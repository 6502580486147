import axios from "axios";
import { API_URL } from "@/const";

export default {
  data() {
    return {
      // API返り値（選択肢などに仕様）
      formValue: null,
      form: null,
      memberOpt: [],
      wasteUnitOpt: [],
      confirmMemberOpt: [],
      shapeOpt: [],
      routesInfo: [],
      collectWayTypeList: [],
      routeDetailInfo: {},
      prefectures: [],
    };
  },
  methods: {
    //マニフェスト情報API    API_URL.MANIFEST.SEARCH + / + {id}
    getManifestInfoApi(manifestId) {
      return axios
        .get(`${API_URL.MANIFEST.SEARCH}/${manifestId}`)
        .then((res) => {
          this.formValue = Object.assign({}, this.formValue, res.data);
          while (this.formValue.contactNoInfo.length < 3) {
            let index = this.formValue.contactNoInfo.length + 1 // IDは利用しないので、一旦インデックスで設定
            this.formValue.contactNoInfo.push({ id: index, contactNo: ""});
          }

          while (this.formValue.remarksInfo.length < 5) {
            let index = this.formValue.remarksInfo.length + 1 // IDは利用しないので、一旦インデックスで設定
            this.formValue.remarksInfo.push({ id: index, detail: ""});
          }
          if (res.data.collectReportList.length) {
            let firstCollectReportList = res.data.collectReportList.filter((collectReport) => 
              collectReport.section === 1)[0];
            this.form = Object.assign({}, this.formValue, firstCollectReportList);
            if (this.form.collectMemberInfo === null) {
              this.form.collectMemberInfo = {
                id: "",
                name: "",
              };
            }
            if (this.form.reportMemberInfo === null) {
              this.form.reportMemberInfo = {
                id: "",
                name: "",
              };
            }
            if (this.form.collectWay === null) {
              this.form.collectWay = {
                id: "",
                name: "",
              };
            }
            if (this.form.collectWasteUnitType === null) {
              this.form.collectWasteUnitType = {
                id: "",
                name: "",
              };
            }
            if (this.form.valuablesUnitType === null) {
              this.form.valuablesUnitType = {
                id: "",
                name: "",
              };
            }
          }
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 404) {
              this.$router.push("/error/404");
              return;
            }
          }
          console.log(err);
        });
    },

    // 都道府県選択肢取得API   DOMAIN.PREFECTURES
    getPrefecturesApi() {
      return axios
          .get(API_URL.DOMAIN.PREFECTURES)
          .then((res) => {
            this.prefectures = res.data.prefectures;
          })
          .catch((err) => {
            console.log(err);
          });
    },

    //運搬担当者選択肢取得API
    getCollectMemberOptApi() {
      axios
      .get(API_URL.MANIFEST.TRANSPOTERS)
        .then((res) => {
          this.memberOpt = res.data.memberList;
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 404) {
              this.$router.push("/error/404");
              return;
            }
          }
          console.log(err);
        });
    },

    // 廃棄物数量単位選択肢取得API    API_URL.DOMAIN.WASTE_UNIT
    getWasteUnitApi() {
      axios
        .get(API_URL.DOMAIN.WASTE_UNIT)
        .then((res) => {
          this.wasteUnitOpt = res.data.wasteUnitTypes;
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 404) {
              this.$router.push("/error/404");
              return;
            }
          }
          console.log(err);
        });
    },

    // ルート選択肢取API     API_URL.ROUTE.LIST + {generateStoreId}
    getRouteListApi(generateStoreId) {
      axios
        .get(`${API_URL.ROUTE.LIST}/${generateStoreId}`)
        .then((res) => {
          this.routesInfo = res.data.routeList;
          const selectedjwnetItemId = this.formValue.routeInfo.jwnetItem.id;
          if (selectedjwnetItemId != null) {
            const newRouteList = this.routesInfo.filter(
              (routeInfo) => routeInfo.jwnetItemType.id == selectedjwnetItemId
            );
            this.narrowedRoutesList = newRouteList;
          } else {
            this.narrowedRoutesList = this.routesInfo;
          }
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 404) {
              this.$router.push("/error/404");
              return;
            }
          }
          console.log(err);
        });
    },

    // 運搬方法選択肢取得API DOMAIN.COLLECT_WAY_TYPE
    getCollectCollectWayTypeApi() {
      axios
        .get(API_URL.DOMAIN.COLLECT_WAY_TYPE)
        .then((res) => {
          this.collectWayTypeList = res.data.collectWayTypeList;
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 404) {
              this.$router.push("/error/404");
              return;
            }
          }
          console.log(err);
        });
    },
  },
};
